var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('base-material-chart-card',{attrs:{"data":_vm.ordersChart.data,"options":_vm.ordersChart.options,"color":"success","hover-reveal":"","type":"Line"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',[_c('h4',{staticClass:"card-title font-weight-light mt-2 ml-2"},[_vm._v(" Orders: "+_vm._s(_vm.totalOrders)+" ")])]),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"text":"","to":"/dashboard/shipments"}},[_vm._v("View more")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('base-material-chart-card',{attrs:{"data":_vm.invoicesChart.data,"options":_vm.invoicesChart.options,"hover-reveal":"","color":"info","type":"Bar"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',[_c('h4',{staticClass:"card-title font-weight-light mt-2 ml-2"},[_vm._v(" Invoices: "+_vm._s(_vm.totalInvoices)+" ")])]),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"text":"","to":"/dashboard/invoices"}},[_vm._v("View more")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"warning"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Recent orders ")]),_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" Recent 10 orders ")])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.recentOrders},on:{"click:row":_vm.handleOrderClick},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"d-block",attrs:{"color":_vm.getOrderStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(_vm.getOrderStatusTitle(item.status)))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.selected_rate.total_cost)+" "+_vm._s(_vm._f("currency")(item.selected_rate.cost_currency))+" ")]}},{key:"item.receiver",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shipment.ship_to.contact_name)+" "+_vm._s(item.shipment.ship_to.company_name)+" ")]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('base-material-stats-card',{attrs:{"color":"info","icon":"mdi-file-check-outline","title":"Pending amount","value":("£ " + _vm.totalPendingAmount),"sub-icon":"mdi-clock","sub-text":"Just Updated"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.invoiceHeaders,"items":_vm.invoices},on:{"click:row":_vm.handleInvoiceClick},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_amount.toFixed(2))+" "+_vm._s(_vm._f("currency")(item.currency))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"d-block text-center",attrs:{"color":_vm.getInvoiceStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(_vm.getInvoiceStatusTitle(item.status)))])]}}])})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }