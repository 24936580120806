<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        lg="8"
      >
        <base-material-chart-card
          :data="ordersChart.data"
          :options="ordersChart.options"
          color="success"
          hover-reveal
          type="Line"
        >
          <v-row justify="space-between">
            <v-col>
              <h4 class="card-title font-weight-light mt-2 ml-2">
                Orders: {{ totalOrders }}
              </h4>
            </v-col>
            <v-col cols="auto" align-self="center">
              <v-btn text to="/dashboard/shipments">View more</v-btn>
            </v-col>
          </v-row>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="invoicesChart.data"
          :options="invoicesChart.options"
          hover-reveal
          color="info"
          type="Bar"
        >
          <v-row justify="space-between">
            <v-col>
              <h4 class="card-title font-weight-light mt-2 ml-2">
                Invoices: {{ totalInvoices }}
              </h4>
            </v-col>
            <v-col cols="auto" align-self="center">
              <v-btn text to="/dashboard/invoices">View more</v-btn>
            </v-col>
          </v-row>
        </base-material-chart-card>
      </v-col>

      <v-col
          cols="12"
          md="8"
      >
        <base-material-card
            color="warning"
            class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Recent orders
            </div>

            <div class="subtitle-1 font-weight-light">
              Recent 10 orders
            </div>
          </template>
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="recentOrders"
                @click:row="handleOrderClick"
            >
              <template v-slot:item.status="{ item }">
                <v-chip class="d-block" :color="getOrderStatusColor(item.status)" dark>{{ getOrderStatusTitle(item.status) }}</v-chip>
              </template>
              <template v-slot:item.amount="{item}">
                {{ item.selected_rate.total_cost }} {{ item.selected_rate.cost_currency | currency}}
              </template>

              <template v-slot:item.receiver="{item}">
                {{ item.shipment.ship_to.contact_name }} {{ item.shipment.ship_to.company_name }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-file-check-outline"
          title="Pending amount"
          :value="`£ ${totalPendingAmount}`"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        >
          <template v-slot:content>
            <v-data-table
                hide-default-footer
                :headers="invoiceHeaders"
                :items="invoices"
                @click:row="handleInvoiceClick"
            >
              <template v-slot:item.amount="{item}">
                {{ item.total_amount.toFixed(2) }} {{ item.currency | currency }}
              </template>
              <template v-slot:item.status="{item}">
                <v-chip class="d-block text-center" :color="getInvoiceStatusColor(item.status)" dark>{{ getInvoiceStatusTitle(item.status) }}</v-chip>
              </template>
            </v-data-table>
          </template>
        </base-material-stats-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Invoice from "@/models/Invoice";
  import Order from "@/models/Order";
  import {orderStatuses, invoiceStatuses} from '@/choices';

  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        totalOrders: 0,
        totalInvoices: 0,
        ordersChart: {
          data: {
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        invoicesChart: {
          data: {},
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        // emailsSubscriptionChart: {
        //   data: {
        //     labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
        //     series: [
        //       [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
        //
        //     ],
        //   },
        //   options: {
        //     axisX: {
        //       showGrid: false,
        //     },
        //     low: 0,
        //     high: 1000,
        //     chartPadding: {
        //       top: 0,
        //       right: 5,
        //       bottom: 0,
        //       left: 0,
        //     },
        //   },
        //   responsiveOptions: [
        //     ['screen and (max-width: 640px)', {
        //       seriesBarDistance: 5,
        //       axisX: {
        //         labelInterpolationFnc: function (value) {
        //           return value[0]
        //         },
        //       },
        //     }],
        //   ],
        // },
        headers: [
          {
            sortable: false,
            text: 'Date',
            value: 'ship_date',
          },
          {
            sortable: false,
            text: 'Reference',
            value: 'reference',
          },
          {
            sortable: false,
            text: 'Carrier',
            value: 'selected_rate.shipper_account.title',
          },
          {
            sortable: false,
            text: 'Booking status',
            value: 'status',
            align: 'center',
          },
          {
            sortable: false,
            text: 'Amount',
            value: 'amount',
          },
          {
            sortable: false,
            text: 'Country',
            value: 'shipment.ship_to.country',
          },
          {
            sortable: false,
            text: 'City',
            value: 'shipment.ship_to.city',
          },
          {
            sortable: false,
            text: 'Receiver',
            value: 'receiver',
          },
        ],
        invoiceHeaders: [
          {
            sortable: false,
            text: 'Due date',
            value: 'due_date',
          },
          {
            sortable: false,
            text: 'Reference',
            value: 'reference',
          },
          {
            sortable: false,
            text: 'Amount',
            value: 'amount',
          },
          {
            text: 'Status',
            value: 'status'
          }
        ],
        tabs: 0
      }
    },

    computed: {
      recentOrders() {
        return Order.all()
      },
      pendingInvoices() {
        return Invoice.query().where('status', 'approved').orderBy('due_date', 'desc').all()
      },
      invoices() {
        return Invoice.query().orderBy('due_date', 'desc').all()
      },
      totalPendingAmount() {
        return this.pendingInvoices.reduce((a, item) => a + item.total_amount, 0)
      }
    },

    methods: {
      getOrderStatusColor(status) {
        return orderStatuses.find(choice => choice.value === status).color
      },
      getOrderStatusTitle(status) {
        return orderStatuses.find(choice => choice.value === status).title
      },
      getInvoiceStatusColor(status) {
        return invoiceStatuses.find(choice => choice.value === status).color
      },
      getInvoiceStatusTitle(status) {
        return invoiceStatuses.find(choice => choice.value === status).title
      },
      handleOrderClick(item) {
        this.$router.push({name: 'Shipment', params: {id: item.id}})
      },
      handleInvoiceClick(item) {
        this.$router.push({name: 'Invoice', params: {id: item.id}})
      }
    },
    async created() {
      const res = await this.$api.get('/api/v1/dashboard/statistic/')
      this.ordersChart.data = res.data.orders
      this.invoicesChart.data = res.data.invoices
      this.totalOrders = res.data.total_orders
      this.totalInvoices = res.data.total_invoices

      await Invoice.api().get('/api/v1/accounting/invoices/?ordering=-due_date')
      await Order.api().get('/api/v1/shipping/orders/?expand=shipment,shipment.parcels,shipment.parcels.items,shipment.ship_to,shipment.ship_from,selected_rate,selected_rate.shipper_account')
    }
  }
</script>
